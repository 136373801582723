.App {
  margin-top: 70px;
  font-size: 0.9rem;
  width: 100%;
  height: calc(100vh - 115px);
  overflow: auto;
}

html, body, #root {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.flex-center {
  display: flex;
  justify-content: center;
}

.gap-10 {
  gap: 10px;
}

@keyframes growAndFade {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

  .ocean {
    height: 45px; /* change the height of the waves here */
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
  }


.wave {
  background: url("./assets/wave.svg");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}

@keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-25%);}
    100% {transform: translateX(-50%);}
}

.boxShadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.MuiListItemText-primary {
  text-transform: capitalize;
}
